import { connect } from "react-redux";
import { useEffect } from "react";
import ExportsLoadingMessage from "./ExportsLoadingMessage";
import useExportsPageControls from "../useExportsPageControls";

const ExportsSearchHeader = ({
  itemCount,
  pageNumber,
  pageSize,
  selectedType,
  selectedDestination,
}) => {
  const { handleExportsPageChange, handleExportsPageSizeChange } =
    useExportsPageControls();
  useEffect(() => {
    if (itemCount <= 0) {
      return;
    }
    if (pageNumber > itemCount / pageSize) {
      handleExportsPageChange("export", Math.ceil(itemCount / pageSize));
    }
    if (pageNumber === 0) {
      handleExportsPageChange("export", 1);
    }
  }, [pageSize, pageNumber, itemCount, handleExportsPageChange]);

  //temporarily disabled pagination element - pending a valid method to get accurate count of search result
  // const getMaxPages = () => {
  //   if (isNaN(pageItemCount)) return 1;
  //   return Math.ceil(pageItemCount < pageSize ? 1 : pageItemCount / pageSize);
  // };
  if (selectedType === "default") {
    selectedType = "case";
  }
  return (
    <ExportsSearchHeaderPure
      handleExportsPageSizeChange={handleExportsPageSizeChange}
      pageSize={pageSize}
      selectedType={selectedType}
      selectedDestination={selectedDestination}
    />
  );
};

const mapStateToProps = (state) => ({
  pageNumber: state.pageControls.exports.pageNumber,
  pageSize:
    state.genericExportOptions.selectedType === "case" ||
    state.genericExportOptions.selectedType === "default"
      ? state.pageControls.case.pageSize
      : state.pageControls.exports.pageSize,
  itemCount: state.pageControls.exports.pageItemCount,
  selectedType: state.genericExportOptions.selectedType,
  selectedDestination: state.genericExportOptions.selectedDestination,
});

const ConnectedExportsSearchHeader =
  connect(mapStateToProps)(ExportsSearchHeader);
export default ConnectedExportsSearchHeader;

export const ExportsSearchHeaderPure = ({
  handleExportsPageSizeChange,
  pageSize,
  selectedType,
  selectedDestination,
}) => {
  return (
    <>
      <div id="content-header" className="exportsHeader">
        <div className="exportHeaderLeft">
          {selectedDestination === "default" ? (
            "Select your Export Destination"
          ) : selectedType === "default" ? (
            "Select Export Type"
          ) : (
            <ExportsLoadingMessage />
          )}
        </div>
        <div className="flex-right paginationControl exportHeaderRight">
          <div className="flex flex-middle flex-auto flex-margin-small pagination">
            {
              //temporarily disabled pagination element - pending a valid method to get accurate count of search result
              /* <input
              type="number"
              value={pageNumber}
              min="1"
              step="1"
              max={getMaxPages()}
              onChange={(e) => {
                handleExportPageChange("export", e.target.value);
              }}
              className="paginationElement"
            />
            <span className="fancy paginationElement">of&nbsp;&nbsp;</span>
            <span className="paginationElement">{getMaxPages()}</span>
          </div>
          <div className="pageChangeArrows">
            <i
              disabled={pageNumber <= "1"}
              className={`icon icon-chevron-left tip-top  ${
                pageNumber <= "1" ? "disabled" : ""
              }`}
              onClick={() => handleExportPageChange("export", pageNumber - 1)}
            />
            <i
              disabled={pageNumber >= getMaxPages()}
              className={`icon icon-chevron-right tip-top ${
                pageNumber >= getMaxPages() ? "disabled" : ""
              }`}
              onClick={() => handleExportPageChange("export", pageNumber + 1)}
            />*/
            }
          </div>
          <select
            aria-label="exportPageSizeSelect"
            defaultValue={pageSize}
            onChange={(e) =>
              handleExportsPageSizeChange(selectedType, e.target.value)
            }
          >
            <option value="50">50 Per Page</option>
            <option value="100">100 Per Page</option>
            <option value="250">250 Per Page</option>
          </select>
        </div>
      </div>
    </>
  );
};

import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestExportIncident } from "../Store/mutations";
import "./Incidents.css";
import ExportSettings from "./export.settings.json";

export class ExportForm extends React.Component {
  constructor(props) {
    super(props);
    this.agencyConfig = JSON.parse(localStorage.getItem("AgencyConfig"));
    this.state = {
      referenceLabel: `${ExportSettings.LabelPrefix}${
        ExportSettings.PopulateIncidentNumber && this.props.id
      }`,
    };
  }
  handleReferenceLabelChanged(event) {
    this.setState({ referenceLabel: event.target.value });
  }
  handleClose(event) {
    event.stopPropagation();
    this.props.onClose();
  }
  handleExport(event) {
    this.props.onExport(this.state.referenceLabel);
    this.handleClose(event);
  }

  render() {
    var boundingBox = this.props.boundingBox;
    return ReactDOM.createPortal(
      <div className="modal" onClick={this.handleClose.bind(this)}>
        <div
          className="modal-container"
          onClick={(event) => event.stopPropagation()}
          style={{
            position: "absolute",
            top: `${boundingBox.top + 40}px`,
            left: `${boundingBox.left - 300}px`,
            width: "300px",
          }}
        >
          <div className="modal-body ExportForm">
            <h4>Reference Label</h4>
            <input
              type="text"
              name="referenceLabel"
              value={this.state.referenceLabel}
              onChange={this.handleReferenceLabelChanged.bind(this)}
            />
            <p>
              {this.props.getLabels().description} the Recording Events tagged
              with this {this.agencyConfig.taggingOptions.incidentNumberTagName}
              .
            </p>
          </div>
          <div className="modal-footer">
            <div className="right">
              <button
                id="create-case-button"
                onClick={this.handleExport.bind(this)}
              >
                {this.props.getLabels().buttonLabel}
              </button>
            </div>
          </div>
        </div>
      </div>,
      document.getElementById("modal-root")
    );
  }
}

ExportForm.propTypes = {
  id: PropTypes.string.isRequired,
  referenceLabel: PropTypes.string,
  boundingBox: PropTypes.any.isRequired,
  onExport: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onExport: (referenceLabel) =>
    dispatch(requestExportIncident(ownProps.id, referenceLabel)),
});

const ConnectedExportForm = connect(null, mapDispatchToProps)(ExportForm);

export default ConnectedExportForm;
